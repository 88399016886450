// Routes for Reservations

export default [
  {
    path: "/reservations/create",
    component: () => import("../pages/reservations/ReservationCreatePage.vue"),
  },
  {
    path: "/reservations/:id",
    component: () => import("../pages/reservations/ReservationPage.vue"),
  },
  {
    path: "/reservations/:id/edit",
    component: () => import("../pages/reservations/ReservationEditPage.vue"),
  },
];
