<template>
  <ion-app>
    <slideout-menu></slideout-menu>

    <ion-router-outlet id="main" :animated="isMobile" />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent } from "vue";
import SlideoutMenu from "@/components/base/SlideoutMenu.vue";

import { isPlatform } from "@ionic/vue";
import { StatusBar, Style } from "@capacitor/status-bar";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    SlideoutMenu,
  },

  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },

  computed: {
    isMobile() {
      if (this.windowWidth <= 768) return true;
      return false;
    },
  },

  mounted() {
    if (isPlatform("ios") || isPlatform("android")) {
      StatusBar.setStyle({ style: Style.Light });
    }
    if (isPlatform("android")) {
      StatusBar.setBackgroundColor({ color: "#FFFFFF" });
    }
  },

  ionViewDidEnter() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  ionViewWillLeave() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
});
</script>