<template>
  <ion-item :lines="lines" class="ion-no-padding" :class="lines === 'full' ? 'ion-margin-bottom' : ''">
    <ion-label position="stacked" class="ion-no-padding" v-if="labelText">
      {{ labelText }}
    </ion-label>
    <slot />
  </ion-item>
</template>

<script>
import { IonItem, IonLabel } from "@ionic/vue";

export default {
  props: {
    labelText: {
      type: String,
    },
    lines: {
      type: String,
      default: "none",
    },
  },

  components: {
    IonItem,
    IonLabel,
  },
};
</script>

<style scoped>
ion-label {
  font-size: 16px !important;
  font-weight: 700;
}
</style>