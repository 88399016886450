// Routes for Room

export default [
  {
    path: "/rooms",
    component: () => import("../pages/rooms/RoomsPage.vue"),
  },
  {
    path: "/rooms/create",
    component: () => import("../pages/rooms/RoomCreatePage.vue"),
  },
  {
    path: "/rooms/:id",
    component: () => import("../pages/rooms/RoomPage.vue"),
  },
  {
    path: "/rooms/:id/edit",
    component: () => import("../pages/rooms/RoomEditPage.vue"),
  },
];
