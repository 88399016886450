import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import BaseLayoutOne from "./components/base/BaseLayoutOne.vue";
import BaseLayoutTwo from "./components/base/BaseLayoutTwo.vue";
import BaseCard from "@/components/base/BaseCard.vue";
import BaseSection from "@/components/base/BaseSection.vue";
import BaseInput from "@/components/base/BaseInput.vue";
import BaseFieldDisplay from "@/components/base/BaseFieldDisplay.vue";
import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/custom.css"; // Import custom CSS

// Vuex Store
import store from './store/index.js';

const app = createApp(App)
  .use(IonicVue, { mode: "ios" })
  .use(router)
  .use(store)
  .use(VueAxios, axios);

app.component("base-layout-one", BaseLayoutOne);
app.component("base-layout-two", BaseLayoutTwo);
app.component("base-card", BaseCard);
app.component("base-section", BaseSection);
app.component("base-input", BaseInput);
app.component("base-field-display", BaseFieldDisplay);
app.config.globalProperties.$version = "2.0.0"

router.isReady().then(() => {
  app.mount("#app");
});
