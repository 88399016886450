<template>
  <ion-menu side="start" menu-id="menu" content-id="main" swipe-gesture="false">
    <section class="ion-padding">
      <div>
        <h1>Boram</h1>
        <ion-item class="ion-no-padding" lines="none">
          <ion-avatar slot="start" class="ion-no-padding">
            <img :src="authUser.profile_photo_url
              ? authUser.profile_photo_url
              : 'https://borym.s3.amazonaws.com/media/soft_green_light.png'
              " />
          </ion-avatar>
          <h4>{{ authUser.first_name }} {{ authUser.last_name }}</h4>
        </ion-item>
        <span class="h3 tertiary">{{
          globalProperty ? globalProperty.name : "Headquarters"
        }}</span>
      </div>

      <div class="ion-padding-top">
        <h4>Choose a Property</h4>

        <ion-list>
          <ion-radio-group :value="globalProperty.id">
            <ion-item class="ion-no-padding" v-for="property in properties" :key="property.id">
              <ion-label>{{ property.name }}</ion-label>
              <ion-radio slot="end" :value="property.id" @click="setGlobalProperty({ property: property })"></ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-list>
      </div>
    </section>

    <section class="ion-padding">
      <ion-button class="regular-button" expand="block" @click="logout">Sign Out</ion-button>
    </section>
  </ion-menu>
</template>

<script>
import {
  IonMenu,
  IonItem,
  IonLabel,
  IonButton,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonAvatar,
  menuController,
} from "@ionic/vue";
import { mapGetters, mapActions } from "vuex";

const menuCtrl = menuController;

export default {
  components: {
    IonMenu,
    IonItem,
    IonLabel,
    IonButton,
    IonList,
    IonRadio,
    IonRadioGroup,
    IonAvatar,
  },

  computed: {
    ...mapGetters(["globalProperty", "authUser"]),
  },

  data() {
    return {
      properties: [],
    };
  },

  mounted() {
    if (this.authUser.id)
      this.fetchProperties();
  },

  methods: {
    ...mapActions(["setGlobalProperty", "authLogout"]),

    async fetchProperties() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/properties`)
        .then((response) => {
          this.properties = response.data.properties;

          // Add a Headquarters property
          this.properties.unshift({
            name: "All",
            id: "",
          });
        })
        .catch((error) => { console.log(error) });
    },

    logout() {
      // Close Slideout Menu
      menuCtrl.close();

      // Log out
      this.authLogout();

      // Go to Login Screen
      this.$router.replace("/auth/login");
    },
  },
};
</script>