<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="home" href="/home">
          <ion-icon :icon="home" />
          <ion-label>Home</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="users" href="/users">
          <ion-icon :icon="person" />
          <ion-label>Guests</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="reservations" href="/reservations"
          v-if="authUser.role === 'admin' || authUser.role === 'manager'">
          <ion-icon :icon="bed" />
          <ion-label>RSVPs</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="engagements" href="/engagements">
          <ion-icon :icon="calendar" />
          <ion-label>Engagements</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="more" href="/more">
          <ion-icon :icon="menu" />
          <ion-label>More</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
} from "@ionic/vue";
import { home, person, bed, calendar, menu } from "ionicons/icons";
import { mapGetters } from "vuex";

export default {
  name: "Tabs",

  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
  },

  computed: {
    ...mapGetters(["authUser"]),
  },

  data() {
    return {
      home,
      person,
      bed,
      calendar,
      menu,
    };
  },
};
</script>