import networkMutations from "./mutations.js";
import networkActions from "./actions.js";
import networkGetters from "./getters.js";

export default {
  namespaced: false,
  state() {
    return {
      showLoading: false,
    };
  },
  mutations: networkMutations,
  actions: networkActions,
  getters: networkGetters,
};
