// Routes for Bookings

export default [
  {
    path: "/engagements/create",
    component: () => import("../pages/engagements/EngagementCreatePage.vue"),
  },
  {
    path: "/engagements/:id",
    component: () => import("../pages/engagements/EngagementPage.vue"),
  },
  {
    path: "/engagements/:id/edit",
    component: () => import("../pages/engagements/EngagementEditPage.vue"),
  },
];
