import propertyMutations from "./mutations.js";
import propertyActions from "./actions.js";
import propertyGetters from "./getters.js";

export default {
  namespaced: false,
  state() {
    return {
      globalProperty: {
        name: "Headquarters",
        id: ""
      },
    };
  },
  mutations: propertyMutations,
  actions: propertyActions,
  getters: propertyGetters,
};
