// Routes for Affiliates

export default [
  {
    path: "/affiliates",
    component: () => import("../pages/affiliates/AffiliatesPage.vue"),
  },
  {
    path: "/affiliates/create",
    component: () => import("../pages/affiliates/AffiliateCreatePage.vue"),
  },
  {
    path: "/affiliates/:id",
    component: () => import("../pages/affiliates/AffiliatePage.vue"),
  },
  {
    path: "/affiliates/:id/edit",
    component: () => import("../pages/affiliates/AffiliateEditPage.vue"),
  },
];
