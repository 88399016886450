export default [
  {
    path: "/purchasables",
    component: () => import("../pages/purchasables/PurchasablesPage.vue"),
  },
  {
    path: "/purchasables/create",
    component: () => import("../pages/purchasables/PurchasableCreatePage.vue"),
  },
  {
    path: "/purchasables/:id",
    component: () => import("../pages/purchasables/PurchasablePage.vue"),
  },
  {
    path: "/purchasables/:id/edit",
    component: () => import("../pages/purchasables/PurchasableEditPage.vue"),
  },
];
