export default [
  {
    path: "/inventories",
    component: () => import("../pages/inventories/InventoriesPage.vue"),
  },
  {
    path: "/inventories/:id",
    component: () => import("../pages/inventories/InventoryPage.vue"),
  },
  {
    path: "/inventories/:id/inventory_counts/create",
    component: () => import("../pages/inventories/InventoryCountCreatePage.vue"),
  },
  {
    path: "/inventories/:id/inventory_counts/:inventory_count_id",
    component: () => import("../pages/inventories/InventoryCountPage.vue"),
  },  
  {
    path: "/inventories/:id/inventory_counts/:inventory_count_id/edit",
    component: () => import("../pages/inventories/InventoryCountEditPage.vue"),
  },  
];
