// Routes for Properties

export default [
  {
    path: "/properties",
    component: () => import("../pages/properties/PropertiesPage.vue"),
  },
  {
    path: "/properties/create",
    component: () => import("../pages/properties/PropertyCreatePage.vue"),
  },
  {
    path: "/properties/:id",
    component: () => import("../pages/properties/PropertyPage.vue"),
  },
  {
    path: "/properties/:id/edit",
    component: () => import("../pages/properties/PropertyEditPage.vue"),
  },
];
