export default [
  {
    path: "/resources",
    component: () => import("../pages/resources/ResourcesPage.vue"),
  },
  {
    path: "/resources/create",
    component: () => import("../pages/resources/ResourceCreatePage.vue"),
  },
  {
    path: "/resources/:id",
    component: () => import("../pages/resources/ResourcePage.vue"),
  },
  {
    path: "/resources/:id/edit",
    component: () => import("../pages/resources/ResourceEditPage.vue"),
  },
];
