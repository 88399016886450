import { isPlatform } from "@ionic/vue";

export default {
  authLogin(context, payload) {
    context.commit("setAuthUser", payload.user);
    context.commit("setAuthToken", payload.token);

    let expirationDate = new Date()

    // For Android, set the login expiration date to the next 7:30 time
    if (isPlatform("android") && (!isPlatform("mobileweb") || !isPlatform("web"))) {
      const year = expirationDate.getFullYear();
      const month = expirationDate.getMonth();
      const date = expirationDate.getDate();
      const hours = expirationDate.getHours();

      let targetDateTime;

      if (hours < 7) {
        // Before 7:30 AM, set to 7:30 AM today
        targetDateTime = new Date(year, month, date, 7, 30);
      } else if (hours < 19) {
        // After 7:30 AM and before 7:30 PM, set to 7:30 PM today
        targetDateTime = new Date(year, month, date, 19, 30);
      } else {
        // After 7:30 PM, set to 7:30 AM the next day
        targetDateTime = new Date(year, month, date + 1, 7, 30);
      }

      expirationDate = targetDateTime;
    }

    // For Web, set the login expiration date to 1 day from the time of login
    else {
      expirationDate.setDate(expirationDate.getDate() + 1)
    }

    context.commit("setAuthLoginExpiration", expirationDate);
  },

  authLogout(context) {
    context.commit("setAuthUser", {});
    context.commit("setAuthToken", {});
    context.commit("setAuthLoginExpiration", null);
  },
};
