<template>
  <!-- Title Area -->
  <section class="padding-x-16 margin-top-24">
    <ion-item lines="none" class="ion-no-padding">
      <!-- Title -->
      <div slot="start" class="h2">{{ title }}</div>

      <!-- Right Link -->
      <router-link
        v-if="linkUrl && linkText"
        :to="linkUrl"
        slot="end"
        class="ion-no-margin ion-text-right"
      >
        {{ linkText }}
      </router-link>
    </ion-item>
  </section>

  <!-- Slot -->
  <section class="padding-x-16">
    <slot />
  </section>

  <!-- Bottom Horizonal Rule -->
  <section class="padding-x-16 margin-top-24">
    <hr class="ion-no-margin ion-no-padding" v-if="showBottomBorder"/>
  </section>
</template>

<script>
import { IonItem } from "@ionic/vue";

export default {
  props: {
    title: {
      type: String,
    },
    linkUrl: {
      type: String,
    },
    linkText: {
      type: String,
    },
    showBottomBorder: {
      type: Boolean,
      default: true
    }
  },

  components: { IonItem },
};
</script>

<style scoped>
a {
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
}
</style>