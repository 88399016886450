// Routes for Users

export default [
  {
    path: "/team",
    component: () => import("../pages/team/TeamMemberPage.vue"),
  },
  {
    path: "/team/create",
    component: () => import("../pages/team/TeamMemberCreatePage.vue"),
  },
  {
    path: "/team/:id",
    component: () => import("../pages/team/TeamMemberPage.vue"),
  },
  {
    path: "/team/:id/edit",
    component: () => import("../pages/team/TeamMemberEditPage.vue"),
  },
];
