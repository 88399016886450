// Routes for Services

export default [
  {
    path: "/reporting",
    component: () => import("../pages/reporting/ReportingPage.vue"),
  },
  {
    path: "/reporting/reservations_status",
    component: () => import("../pages/reporting/ReservationsStatusPage.vue"),
  },
];
