// Routes for Affiliates

export default [
  {
    path: "/employers",
    component: () => import("../pages/employers/EmployersPage.vue"),
  },
  {
    path: "/employers/create",
    component: () => import("../pages/employers/EmployerCreatePage.vue"),
  },
  {
    path: "/employers/:id",
    component: () => import("../pages/employers/EmployerPage.vue"),
  },
  {
    path: "/employers/:id/edit",
    component: () => import("../pages/employers/EmployerEditPage.vue"),
  },
];
