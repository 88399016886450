// Routes for Room

export default [
  {
    path: "/tasks",
    component: () => import("../pages/tasks/TasksPage.vue"),
  },
  {
    path: "/tasks/create",
    component: () => import("../pages/tasks/TaskCreatePage.vue"),
  },
  {
    path: "/tasks/:id",
    component: () => import("../pages/tasks/TaskPage.vue"),
  },
  {
    path: "/tasks/:id/edit",
    component: () => import("../pages/tasks/TaskEditPage.vue"),
  },
];
