export default {
  loadToast(context, toast){
    context.commit('setToast', toast)
    context.commit('setShowToast', true)
  },

  resetToast(context){
    context.commit('setToast', { message: "", color: "secondary"})
    context.commit('setShowToast', false )
  }
};
