<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button :default-href="pageDefaultBackLink">
          </ion-back-button>
        </ion-buttons>
        <ion-title>{{ pageTitle }}</ion-title>
        <ion-buttons slot="end" class="padding-right-8">
          <router-link :to="endButtonUrl" v-if="endButtonUrl">
            {{ endButtonText }}
          </router-link>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" :class="contentClass">
      <slot />
    </ion-content>

    <ion-toast
      :is-open="showToast"
      :message="toast.message"
      :duration="2000"
      @didDismiss="resetToast"
      :color="toast.color"
    >
    </ion-toast>

    <ion-loading
      :is-open="showLoading"
      message="Please wait..."
      @didDismiss="setShowLoading(false)"
    >
    </ion-loading>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonContent,
  IonBackButton,
  IonToast,
  IonLoading,
} from "@ionic/vue";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    pageTitle: {
      type: String,
    },
    pageDefaultBackLink: {
      type: String,
      required: true,
    },
    endButtonText: {
      type: String,
    },
    endButtonUrl: {
      type: String,
    },
    contentClass: {
      type: String,
      default: "background-light",
    },
  },

  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonContent,
    IonBackButton,
    IonToast,
    IonLoading,
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters(["showToast", "toast", "showLoading"]),
  },

  methods: {
    ...mapActions(["resetToast", "setShowLoading"]),
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

ion-toolbar {
  --background: #fff;
}

.test {
  --background: #000;
}
</style>