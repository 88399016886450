// Routes for Users

export default [
  {
    path: "/users/create",
    component: () => import("@/pages/users/UserCreatePage.vue"),
  },
  {
    path: "/users/:id",
    component: () => import("@/pages/users/UserPage.vue"),
  },
  {
    path: "/users/:id/profile",
    component: () => import("@/pages/users/UserProfilePage.vue"),
  },
  {
    path: "/users/:id/profile/edit",
    component: () => import("@/pages/users/UserProfileEditPage.vue"),
  },
  {
    path: "/users/:id/activities",
    component: () => import("@/pages/users/activities/ActivitiesPage.vue"),
  },
  {
    path: "/users/:id/activities/:activity_id",
    component: () => import("@/pages/users/activities/ActivityPage.vue"),
  },
  {
    path: "/users/:id/activities/:activity_id/edit",
    component: () => import("@/pages/users/activities/ActivityEditPage.vue"),
  },
  {
    path: "/users/:id/activities/select",
    component: () => import("@/pages/users/activities/ActivitySelectPage.vue"),
  },
  {
    path: "/users/:id/activities/create",
    component: () => import("@/pages/users/activities/ActivityCreatePage.vue"),
  },
  {
    path: "/users/:id/activities/notes",
    component: () => import("@/pages/users/activities/NotesPage.vue"),
  },
  {
    path: "/users/:id/activities/notes/create",
    component: () => import("@/pages/users/activities/NoteCreatePage.vue"),
  },
  {
    path: "/users/:id/care_plans/create",
    component: () => import("@/pages/users/care_plans/CarePlanCreatePage.vue"),
  },
  {
    path: "/users/:id/care_plans/:care_plan_id",
    component: () => import("@/pages/users/care_plans/CarePlanPage.vue"),
  },
  {
    path: "/users/:id/care_plans/:care_plan_id/edit",
    component: () => import("@/pages/users/care_plans/CarePlanEditPage.vue"),
  },
  {
    path: "/users/:id/reservations",
    component: () => import("@/pages/users/reservations/ReservationsPage.vue"),
  },
  {
    path: "/users/:id/payment_methods/create",
    component: () => import("@/pages/users/paymentmethods/PaymentMethodCreatePage.vue"),
  },
  {
    path: "/users/:id/payment_methods/:payment_method_id",
    component: () => import("@/pages/users/paymentmethods/PaymentMethodPage.vue"),
  },
  {
    path: "/users/:id/payment_methods/:payment_method_id/edit",
    component: () => import("@/pages/users/paymentmethods/PaymentMethodEditPage.vue"),
  },
  {
    path: "/users/:id/continued_care/epds_questionnaires/:epds_questionnaire_id",
    component: () => import("@/pages/users/continued_care/EpdsQuestionnairePage.vue"),
  },
  {
    path: "/users/:id/continued_care/cc_notes",
    component: () => import("@/pages/users/continued_care/CcNotesPage.vue"),
  },
  {
    path: "/users/:id/continued_care/cc_notes/create",
    component: () => import("@/pages/users/continued_care/CcNoteCreatePage.vue"),
  },
  {
    path: "/users/:id/folios",
    component: () => import("@/pages/users/folios/FoliosPage.vue"),
  },
  {
    path: "/users/:id/folios/create",
    component: () => import("@/pages/users/folios/FolioCreatePage.vue"),
  },
  {
    path: "/users/:id/folios/:folio_id",
    component: () => import("@/pages/users/folios/FolioPage.vue"),
  },
  {
    path: "/users/:id/folios/:folio_id/transactions/create",
    component: () => import("@/pages/users/folios/TransactionCreatePage.vue"),
  },
  {
    path: "/users/:id/folios/:folio_id/transactions/:transaction_id",
    component: () => import("@/pages/users/folios/TransactionPage.vue"),
  },
  {
    path: "/users/:id/folios/:folio_id/transactions/:transaction_id/edit",
    component: () => import("@/pages/users/folios/TransactionEditPage.vue"),
  },
  {
    path: "/users/:id/folios/:folio_id/payments/create",
    component: () => import("@/pages/users/folios/PaymentCreatePage.vue"),
  },
  {
    path: "/users/:id/resources",
    component: () => import("@/pages/users/resources/ResourcesPage.vue"),
  }
];
