export default {
  setAuthUser(state, payload) {
    state.authUser = payload;
  },

  setAuthToken(state, payload) {
    state.authToken = payload;
  },

  setAuthLoginExpiration(state, payload) {
    state.authLoginExpiration = payload;
  },
};
