// Routes for Room Types

export default [
  {
    path: "/room_types",
    component: () => import("../pages/roomTypes/RoomTypesPage.vue"),
  },
  {
    path: "/room_types/create",
    component: () => import("../pages/roomTypes/RoomTypeCreatePage.vue"),
  },
  {
    path: "/room_types/:id",
    component: () => import("../pages/roomTypes/RoomTypePage.vue"),
  },
  {
    path: "/room_types/:id/edit",
    component: () => import("../pages/roomTypes/RoomTypeEditPage.vue"),
  },
];
