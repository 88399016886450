<template>
  <ion-card class="
        ion-no-margin
        padding-x-16 
        padding-y-16
      ">
    <!-- Title Area -->
    <section>
      <ion-item lines="none" class="ion-no-padding" v-if="title || linkText">
        <!-- Title -->
        <div slot="start">
          <div class="h2">{{ title }}</div>
          <div class="p" v-if="subtitle">{{ subtitle }}</div>
        </div>

        <!-- Right Link -->
        <router-link v-if="linkUrl && linkText" :to="linkUrl" slot="end" class="ion-no-margin ion-text-right">
          {{ linkText }}
        </router-link>
      </ion-item>
    </section>

    <section>
      <slot />
    </section>
  </ion-card>
</template>

<script>
import { IonCard, IonItem } from "@ionic/vue";

export default {
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    linkUrl: {
      type: String,
    },
    linkText: {
      type: String,
    },
  },

  components: {
    IonCard,
    IonItem,
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
}

ion-card {
  box-shadow: rgb(0 0 0 / 12%) 0px 1px 1px;
  color: var(--ion-color-dark);
}
</style>