// Routes for Settings

export default [
  {
    path: "/scan",
    component: () => import("../pages/scan/ScanPage.vue"),
  },
  {
    path: "/scan/ebmin",
    component: () => import("../pages/scan/ScanEBMInPage.vue"),
  },
  {
    path: "/scan/preparebottle",
    component: () => import("../pages/scan/ScanPrepareBottlePage.vue"),
  },
  {
    path: "/scan/ebmout",
    component: () => import("../pages/scan/ScanEBMOutPage.vue"),
  },
  {
    path: "/scan/babyinroom",
    component: () => import("../pages/scan/ScanBabyInRoomPage.vue"),
  },
];
