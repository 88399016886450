// Routes for Shifts

export default [
  {
    path: "/shifts",
    component: () => import("../pages/shifts/ShiftsPage.vue"),
  },
  {
    path: "/shifts/create",
    component: () => import("../pages/shifts/ShiftCreatePage.vue"),
  },
];
