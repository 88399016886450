import { createRouter, createWebHistory } from "@ionic/vue-router";
// import { RouteRecordRaw } from 'vue-router';
import Tabs from "../views/Tabs.vue";
import authRoutes from "./auth";
import affiliateRoutes from "./affiliates";
import employerRoutes from "./employers";
import engagementRoutes from "./engagements";
import financialRoutes from "./financials";
import inventoryRoutes from "./inventories";
import userRoutes from "./users";
import teamRoutes from "./team";
import reservationRoutes from "./reservations";
import resourceRoutes from "./resources";
import roomRoutes from "./rooms";
import roomTypeRoutes from "./roomTypes";
import propertyRoutes from "./properties";
import shiftRoutes from "./shifts";
import taskRoutes from "./tasks";
import purchasbleRoutes from "./purchasables";
import reportingRoutes from "./reporting";
import scannerRoutes from "./scanner";
import settingsRoutes from "./settings";

import store from "../store/index.js";
import axios from "axios";

import { isPlatform } from "@ionic/vue";
import { userHasRole } from "../util/helpers";

const routes = [
  {
    path: "/",
    redirect: "/home",
    component: Tabs,
    children: [
      {
        path: "",
        redirect: "/home",
      },
      {
        path: "home",
        component: () => import("@/pages/HomePage.vue"),
      },
      {
        path: "users",
        component: () => import("@/pages/users/UsersPage.vue"),
      },
      {
        path: "reservations",
        component: () => import("@/pages/reservations/ReservationsPage.vue"),
      },
      {
        path: "engagements",
        component: () => import("@/pages/engagements/EngagementsPage.vue"),
      },
      {
        path: "more",
        component: () => import("@/pages/MorePage.vue"),
      },
    ],
  },
  ...authRoutes,
  ...affiliateRoutes,
  ...employerRoutes,
  ...engagementRoutes,
  ...financialRoutes,
  ...inventoryRoutes,
  ...reservationRoutes,
  ...propertyRoutes,
  ...reportingRoutes,
  ...resourceRoutes,
  ...roomRoutes,
  ...roomTypeRoutes,
  ...purchasbleRoutes,
  ...scannerRoutes,
  ...settingsRoutes,
  ...shiftRoutes,
  ...taskRoutes,
  ...teamRoutes,
  ...userRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // Set authorization token on axios if it exists
  if (store.getters.authLoggedIn) {
    axios.defaults.headers.common = {
      Authorization: `bearer ${store.getters.authToken.token}`,
    };
  }

  // Guard if the user is not logged in, send them to login
  if (!store.getters.authLoggedIn && to.path !== "/auth/login") {
    store.dispatch("authLogout")
    next({ path: "/auth/login" });
  }

  // Guard to see if they are allowed to access the web
  else if (!userHasRole(store.getters.authUser, "web") && to.path !== "/auth/login" && (isPlatform("mobileweb") || isPlatform("desktop"))) {
    store.dispatch("authLogout")
    next({ path: "/auth/login" });
  }

  /**
   * Guard to see if their login expiration has finished
   * For Web, it's 1 day after last login
   * For Android, it's at 7:30am or 7:30pm depending on when the login was
   * */ 
  if (to.path !== "/auth/login" && (store.getters.authLoginExpiration === null || new Date(store.getters.authLoginExpiration) <= new Date())) {
    store.dispatch("authLogout")
    next({ path: "/auth/login" });
  }

  // Else just go
  else next();
});

export default router;
