import { format, parseISO } from "date-fns";

function formatCurrency(amount) {
  if (amount)
    return amount.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  if (amount === 0) return 0
  else
    return "N/A"
}

function formatDate(dateString, formatStyle) {
  if (dateString) return format(parseISO(dateString), formatStyle);

  return "N/A";
}

function formatDateOnlyNoTimezone(dateString) {
  if (dateString) {
    const [year, month, day] = dateString.substr(0, 10).split("-");

    let date = new Date();
    date.setFullYear(year);
    date.setMonth(month - 1);
    date.setDate(day);

    return format(date, "MMMM d, yyyy");
  }
  return "N/A";
}

function formatTimeAndDate(dateString) {
  if (dateString) {
    let utcDateString = dateString.replace(" ", "T").concat(".000Z")
    return format(parseISO(utcDateString), "h:mmaaa 'on' M/d/yy (eeee)");
  }

  return "N/A";
}

function format24to12hr(timeString) {
  if (timeString) {
    let components = timeString.split(":");
    let hourString = components[0]
    let minuteString = components[1]

    let hours = Number.parseInt(hourString)

    let twelveHourString = (hours > 12) ? (hours - 12).toString() : hours.toString()
    let ampm = (hours >= 12) ? "pm" : "am"

    return `${twelveHourString}:${minuteString}${ampm}`
  }

  return "N/A";
}

function userHasRole(user, role) {
  if (user.roles) {
    let found = user.roles.find((x) => x.name === role)

    if (found) return true
  }

  return false;
}

export { formatCurrency, formatDate, formatDateOnlyNoTimezone, formatTimeAndDate, format24to12hr, userHasRole };
