import alertsMutations from "./mutations.js";
import alertsActions from "./actions.js";
import alertsGetters from "./getters.js";

export default {
  namespaced: false,
  state() {
    return {
      showToast: true,
      toast: {
        message: "",
        color: "secondary"
      },
    };
  },
  mutations: alertsMutations,
  actions: alertsActions,
  getters: alertsGetters,
};
