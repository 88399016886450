import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";

import rootMutations from './mutations.js';
import rootActions from './actions.js';
import rootGetters from './getters.js';
import propertyModule from './modules/property/index.js';
import alertsModule from './modules/alerts/index.js';
import networkModule from './modules/network/index.js';
import authModule from './modules/auth/index.js';

const store = createStore({
  modules: {
    properties: propertyModule,
    alerts: alertsModule,
    network: networkModule,
    auth: authModule
  },
  state() {
    return {
    };
  },
  plugins: [createPersistedState()],
  mutations: rootMutations,
  actions: rootActions,
  getters: rootGetters
});

export default store;